"use client";
import React from 'react'
import NavBar from '../NavBar'
import {BackgroundBeams} from '../ui/background-beams'

import mission from '../../assets/mission.png'
import vision from '../../assets/vision.png'
import values from '../../assets/values.png'

const Card = ({ image, heading, details }) => {
  return (
    <div className="flex flex-col justify-center items-center max-w-sm rounded overflow-hidden py-14 bg-white hover:shadow-lg">
      <img className="w-20 bounce-on-hover" src={image} alt="Card" />
      <div className="flex flex-col justify-center items-center px-6 py-4">
        <div className="font-bold lg:text-xl text-sm mb-2 text-center text-blue-950">{heading}</div>
        <p className="text-gray-700 lg:text-base text-sm text-center">{details}</p>
      </div>
    </div>
  );
};


const Banner = () => {

  return (
    <div className='flex flex-col justify-center items-center w-full'>

      <section className="lg:h-[25rem]  w-full  bg-gradient-to-t from-blue-950 via-blue-800 to-blue-950 relative flex
        flex-col items-center antialiased lg:pb-0 pb-16">
      <NavBar />

      <div className='w-full h-full flex flex-col justify-center items-center max-w-7xl'>
        <h1 className='text-3xl font-Poppins font-extrabold text-white text-center'>
          About Us
        </h1>
        <p className='mt-2 text-white font-Poppins text-sm text-center'>
          Home <span className='text-orange-500 text-5xl'>.</span> About Us
        </p>
      </div>
      <BackgroundBeams />
    </section>

    <section className="flex sm:flex-row flex-col justify-center gap-5 lg:-mt-10 sm:-mt-10 mt-8 z-0 lg:px-0 px-4">
      <Card
          image= {mission}
          heading="Mission"
          details="
              IMO-Tech is on a mission to revolutionize the digital landscape, providing
              cutting-edge solutions to empower businesses and individuals in the digital era.
          "
      />
      <Card
          image={vision}
          heading="Vision"
          details="
              IMO-Tech envisions a tech-driven future where creativity flourishes through
              seamless integration, anticipating and meeting the evolving needs of our clients.
          "
      />
      <Card
          image={values}
          heading="Values"
          details="
              Rooted in excellence and integrity, IMO-Tech values craftsmanship, fostering innovation to deliver
              solutions that are both functional and elegantly user-friendly.
          "
      />
    </section>

    </div>

  )
}

export default Banner

"use client";
import React from "react";
import { Boxes } from "../ui/background-boxes";
import { cn } from "../../utils/cn";
import { Link } from "react-router-dom";


const ActionButtonSection = () => {
  return (
    <div className="flex justify-center items-center w-full">

    <div className="sm:h-48 relative w-full overflow-hidden bg-slate-900 flex flex-col items-center justify-center py-5 px-3">
      <div className="absolute inset-0 w-full h-full bg-slate-900 z-20 [mask-image:radial-gradient(transparent,white)]
      pointer-events-none" />

      <Boxes />
      <h1 className={cn("md:text-4xl text-xl text-white relative z-20 sm:text-center")}>
        Let IMO-Tech Solutions transform your business
      </h1>
      <p className="text-center mt-2 text-neutral-300 relative z-20 sm:text-center">
        Let us rebrand your business by making you reach more customers with our technologies
      </p>

        <Link to='/contact_us'
          className="relative inline-flex h-12 overflow-hidden rounded-sm p-[1px] focus:outline-none
            focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 my-5 ">
            <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite]
                bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
            <span className="inline-flex h-full w-full cursor-pointer items-center justify-center
            rounded-sm px-5 hover:bg-gray-800 bg-gray-50 py-1 text-sm font-medium hover:text-white
            text-gray-700 backdrop-blur-3xl">
              LET US HELP YOU
            </span>
        </Link>
    </div>
    </div>
  )
}

export default ActionButtonSection

import React from 'react'

import back_1 from '../../assets/back_1.png'
import ceo from '../../images/ceo.jpeg'
const MeetOurCEO = () => {
  return (
    <div className='flex justify-center items-center w-full'>
      <div className='flex sm:flex-row flex-col justify-center items-center w-full sm:py-6 py-12 lg:px-3 px-3 mt-5 max-w-7xl'>
        <div className=' flex justify-center items-center sm:w-1/2 w-full lg:h-[40rem]'>
          <img src={ceo} alt="ceo" className='lg:h-[36rem] object-cover'
          />
        </div>

        <div className='sm:w-1/2 w-full px-3 font-Poppins sm:mt-0 mt-7 bg-cover bg-center bg-no-repeat'
          style={{ backgroundImage: `url(${back_1})` }}
        >
          <h1 className='text-orange-500 font-mono'>MEET OUR CEO</h1>
          <h2 className='lg:py-3 py-5 text-blue-950 font-bold text-2xl'>Mohamed Fofana</h2>
          <p className='pb-10 text-gray-700 font-semibold text-xl'>
            Bachelors in Economics <br/> Masters in Business Administration
          </p>

          <p className='text-sm text-gray-700 font-Archivo max-w-[30rem] '>
            Mr. Mohamed Fofana is the Founder and CEO of Imo Tech Solutions. Mr. Fofana is responsible for all company
            operations and setting Imo Tech Solutions’ strategic direction. Mr. Fofana knows that the success of Imo
            Tech Solutions depends largely on the company’s ability to meet and surpass customers’ expectation. Mr.
            Fofana will also focus on building and maintaining a company culture that allows individuals the ability to
            perform their best in service of the customer. He strongly believes that quality people will produce quality
            results. Mr. Fofana has over 17 years of professional experience in the I.T Industry. He has experience
            working for both private companies and Federal Government Agencies. Mr. Fofana technical specialties include
            operations, database management, and cloud solutions. <br/> <br/>

            Mr. Fofana is a strong advocate of giving back to those less fortunate. He is a board member of Smile of
            Hope International, a non-profit organization supporting vulnerable women and children in developing
            countries gain access to food, healthcare and education.
          </p>

          <div className='flex flex-row gap-6 w-full mt-5'>

            <a target="_blank" href='#'>
              <svg className="w-5 text-blue-500 bounce-on-hover"
              fill="currentColor"
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path>
              </svg>
            </a>

            <a target="_blank" href='#'>
              <svg className="w-5 text-red-500 bounce-on-hover"
                fill="currentColor"
                viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
              </svg>
            </a>

            <a target="_blank" href='#'>
              <svg className="w-5 text-blue-500 bounce-on-hover"
                fill="currentColor"
                viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
              </svg>
            </a>

          

            <a target="_blank" href='#'>
              <svg className="w-5 text-gray-800 bounce-on-hover"
                fill="currentColor"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <path  d="M13.8 10.5 20.7 2h-3l-5.3 6.5L7.7 2H1l7.8 11-7.3 9h3l5.7-7 5.1 7H22l-8.2-11.5Zm-2.4 3-1.4-2-5.6-7.9h2.3l4.5 6.3 1.4 2 6 8.5h-2.3l-4.9-7Z"/>
              </svg>
            </a>

					  <a target="_blank" href='#'>
              <svg className="w-5 text-red-500 bounce-on-hover"
                fill="currentColor"
                viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path>
              </svg>
            </a>
            </div>

        </div>
      </div>
    </div>
  )
}

export default MeetOurCEO

import React from 'react'

import back_1 from '../../assets/back_1.png'
import logo from '../../assets/logo_icon.png'
import { Link } from 'react-router-dom'

const AboutText = () => {
  return (
    <div className='flex justify-center items-center w-full'>
      <div className='flex sm:flex-row-reverse flex-col justify-center items-center w-full sm:py-0 py-12 lg:px-3 px-3 mt-5 max-w-7xl'>
        <div className=' flex justify-center items-center sm:w-1/2 w-full lg:h-[40rem]'>
          <img src={logo} alt="logo" className='lg:h-[29rem] object-cover'
          />
        </div>

        <div className='sm:w-1/2 w-full px-3 font-Poppins sm:mt-0 mt-7 bg-cover bg-center bg-no-repeat'
          style={{ backgroundImage: `url(${back_1})` }}
        >
          <h1 className='text-orange-500 font-mono'>ABOUT US</h1>
          <h2 className='lg:py-10 py-5 text-blue-950 font-bold text-2xl'>Welcome to IMO-Tech Solutions</h2>
          <p className='pb-10 text-gray-700 font-semibold text-xl'>
                Our Profound Expertise in <br/> Technological Innovation
          </p>

          <p className='text-lg text-gray-700 font-Archivo max-w-[30rem] '>
            At IMO-Tech Solutions, we are more than just a software house – we are a dynamic team of tech enthusiasts
            dedicated to crafting innovative solutions. With a passion for pushing boundaries and a commitment
            to delivering top-notch services, IMO-Tech stands at the forefront of software development, and beyond.
          </p>

          <Link to='/services'
            className="relative inline-flex h-12 overflow-hidden rounded-sm p-[1px] focus:outline-none
            focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 my-10 ">
            <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
            <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-sm px-5 hover:bg-gray-800 bg-gray-50 py-1 text-sm font-medium hover:text-white text-gray-700 backdrop-blur-3xl">
              CHECK OUT OUR SERVICES
            </span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default AboutText

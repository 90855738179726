import { NavLink } from "react-router-dom";
import { useState } from "react";
import logo from "../assets/logo.png"

const navigation = [
  { name: "HOME", href: "/", current: true },
  { name: "ABOUT US", href: "/about", current: false },
  { name: "SERVICE", href: "/services", current: false },
  { name: "PROJECTS", href: "/projects", current: false },
  { name: "CONTACT US", href: "/contact_us", current: false },
];

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


  return (
    <nav className="top-0 w-full z-10 text-sm font-Poppins
    text-white font-bold p-2"
    aria-label="Top">
      <div className="mx-auto px-4 sm:px-6 py-6 lg:px-8 max-w-[95rem]">
        <div className="flex justify-between sm:h-8 h-6">
          <div className="flex items-center ml-20">
            <NavLink to="/" className="text-sm font-bold text-blue-500">
              <img
                className="block h-10 w-auto lg:hidden"
                src={logo}
                alt="Your Company"
              />
              <img
                className="hidden w-32  lg:block"
                src={logo}
                alt="Your Company"
              />
            </NavLink>
          </div>

          <div className="flex items-center -mr-2 sm:hidden">
            <button
              onClick={toggleMenu}
              type="button"
              className="inline-flex transition duration-150 ease-in-out"
            >
              <svg
                className="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
                />
              </svg>
            </button>
          </div>

          <div className="lg:flex justify-center w-full hidden sm:flex">
            <div className="hidden sm:flex sm:items-center sm:ml-6 mr-20 gap-5">
              {navigation.map((item, index) => {
                return (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className=" transition hover:font-extrabold hover:text-orange-500
                      duration-150 ease-in-out"
                      onClick={toggleMenu}
                  >
                    {item.name}
                  </NavLink>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="sm:hidden">
          <div className="pt-2 pb-3 flex flex-col justify-center items-center bg-gray-800 gap-5">
            {navigation.map((item, index) => {

              return (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className="block transition hover:font-extrabold hover:text-orange-500
                  duration-150 ease-in-out"
                  onClick={toggleMenu}
                >
                  {item.name}
                </NavLink>
              );
            })}
          </div>
        </div>
      )}
    </nav>
  );
}

export default NavBar;


import React from 'react'
import Banner from '../components/about/Banner'
import AboutText from '../components/about/AboutText'
import TechnologyPioneer from '../components/about/TechnologyPioneer'
import WhatWeDo from '../components/about/WhatWeDo'
import ProjectVoyage from '../components/about/ProjectVoyage'
import MeetOurCEO from '../components/about/MeetOurCEO'

import Executive from '../components/about/Executive'
import Team from '../components/about/Team'
import Testimonials from '../components/about/Testimonials'
import ActionButtonSection from '../components/about/ActionButtonSection'
import Footer from '../components/Footer'
import TrustedBy from '../components/about/TrustedBy'

const About = () => {
  return (
    <div className='flex flex-col justify-center items-center w-full bg-white'>
      <div className='w-full max-w-[125rem]'>
      <div className="flex flex-col items-center w-full bg-gradient-to-t from-blue-50 to-gray-50 pb-10">
        <Banner/>
        <AboutText/>
        <TechnologyPioneer/>
        <WhatWeDo/>
      </div>
      <ProjectVoyage/>
      <MeetOurCEO/>

      <div className="flex flex-col items-center w-full bg-gradient-to-t from-blue-50 to-gray-50 pb-10">
        <Executive/>
        <Team/>
        <Testimonials/>
        <ActionButtonSection/>
        <TrustedBy/>
        <Footer/>
      </div>
      </div>

    </div>
  )
}

export default About

import React from 'react'

import back_2 from '../../assets/back_2.png'

import digital_marketing from '../../assets/digital_marketing.png'
import graphic_design from '../../assets/graphic_designing.png'
import sco from '../../assets/sco.png'
import ui_design from '../../assets/ui_design.png'
import web_design from '../../assets/web_designing.png'

import Lottie from 'lottie-react'
import cloud_lottie from '../../lottie/cloud_stars.json'

const Card2 = ({ image, heading, details }) => {
    return (
      <div className="flex flex-col justify-center items-center max-w-sm w-full overflow-hidden mt-5 p-4 bg-white rounded-lg
        hover:shadow bounce-on-hover cursor-pointer py-8">
        <img className="w-24 pt-3" src={image} alt="Card" />
        <div className="flex flex-col justify-center items-start px-6 py-4">
          <div className="font-bold lg:text-xl text-sm mb-2 text-start text-blue-950">{heading}</div>
          <p className="text-gray-700 lg:text-base text-sm text-start">{details}</p>
        </div>
      </div>
    );
  };

const OurServices = () => {
  return (
    <div className='w-full flex flex-col justify-center items-center bg-center bg-cover bg-no-repeat lg:pb-0 pb-10'
    style={{ backgroundImage: `url(${back_2})` }}
    >

        <div className="lg:w-1/2 w-full max-w-7xl grid sm:grid-cols-3 lg:px-0 px-4 pt-16 gap-5 bg-cover bg-no-repeat bg-center
            relative z-10 lg:mb-16
        "
        >

            <div className="mt-0 w-full gap-5">
                <div className="flex flex-col justify-start">
                <h1 className='text-start text-blue-950 sm:pt-[10rem]  font-poppins font-bold sm:text-3xl text-xl'>
                    <span className='text-orange-500 font-mono text-lg'> OUR SERVICES</span> <br/>
                    Let’s Check Our Services
                </h1>
                <p className="text-gray-700 lg:text-base text-sm text-start sm:py-0 py-6">
                    IMO-Tech provides these services to empower businesses in the digital landscape.
                </p>
                </div>
                <Card2
                image={web_design}
                heading="Web Designing & Development"
                details="
                    At IMO-Tech, we excel in designing and developing captivating websites that not only
                    reflect your brand identity but also provide an engaging user experience.
                "
                />
            </div>

            <div className="mt-5 gap-5">
            <Card2
            image={graphic_design}
            heading="Graphic Designing"
            details="
                Transform your brand visuals with IMO-Tech's graphic design expertise, where creativity meets strategy
                to produce compelling designs that leave a lasting impact.
            "
            />
            <Card2
            image={ui_design}
            heading="UI - UX Design"
            details="
                At IMO-Tech, we craft seamless user experiences. Our designs blend creativity with functionality,
                ensuring intuitive interactions. Elevate your digital initiatives with visually impressive and
                user-friendly interfaces.
            "
            />
            </div>

            <div className="mt-14 gap-5">
            <Card2
            image={sco}
            heading="Search Engine Optimization"
            details="
                Elevate your online presence with IMO-Tech's SEO expertise. We boost visibility through proven
                strategies, tailored to your goals. Climb search rankings and stand out in the digital realm.
            "
            />
            <Card2
            image={digital_marketing}
            heading="Digital Marketing"
            details="
                Boost your online presence with IMO-Tech's digital marketing prowess, encompassing SEO, social media,
                and targeted campaigns to enhance your brand's visibility and impact.
            "
            />
            </div>
        </div>


        <div className='w-full absolute z-0 lg:mt-[10rem] sm:mt-[43rem] xx:mt-[128rem] xs:mt-[124rem] pro:mt-[118rem] max-w-[125rem]'>
            <Lottie
                animationData={cloud_lottie} loop={true}
            />
        </div>

    </div>
  )
}

export default OurServices

import React from 'react';

import moiwa from '../../images/img_4.jpg'


import {
  Carousel,
  initTWE,
} from "tw-elements";
import { Link } from 'react-router-dom';

initTWE({ Carousel });
const Testimonials = () => {


  // Testimonials data
  const testimonials = [
    {
      name: 'Anna Doe',
      role: 'Graphic designer',
      content: 'Nunc tincidunt vulputate elit. Mauris varius purus malesuada neque iaculis malesuada. Aenean gravida magna orci, non efficitur est porta id. Donec magna diam.',
      rating: 5,
      image: moiwa
    },
    {
        name: 'Anna Doe',
        role: 'Graphic designer',
        content: 'Nunc tincidunt vulputate elit. Mauris varius purus malesuada neque iaculis malesuada. Aenean gravida magna orci, non efficitur est porta id. Donec magna diam.',
        rating: 5,
        image: moiwa
      },


    // Add more testimonials here
  ];

  return (
    <div className="flex flex-col justify-center items-center w-full mb-24 mx-auto md:px-6">
      <div className='w-full max-w-7xl px-3'>
         <h1 className='text-orange-500 font-mono'>Testimonials</h1>
          <h2 className='lg:pb-10 py-5 text-blue-950 font-bold text-2xl'>what our clients says about us</h2>
      </div>

      <section className="flex sm:flex-row flex-col justify-center items-center mb-2 text-center lg:text-left max-w-7xl px-3">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="flex  items-center lg:w-[30rem] sm:ml-2 sm:mr-2">
            <div className="mb-12 md:mt-12 lg:mt-0 ">
              <div
                className="relative z-[1] block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[25px] dark:bg-[hsla(0,0%,5%,0.7)] dark:shadow-black/20 md:px-12 lg:-mr-14">
                <h2 className="mb-2 text-3xl font-bold text-primary dark:text-primary-400">
                  {testimonial.name}
                </h2>
                <p className="mb-4 font-semibold">{testimonial.role}</p>
                <p className="mb-6 text-neutral-500 dark:text-neutral-300">
                  {testimonial.content}
                </p>
                <ul className="flex justify-center lg:justify-start">
                  {[...Array(testimonial.rating)].map((_, index) => (
                    <li key={index}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960"
                        className="w-5 text-orange-500">
                        <path fill="currentColor"
                          d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                      </svg>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:mb-12 lg:mb-0">
              <img src={testimonial.image} className="lg:rotate-[6deg] w-full rounded-lg shadow-lg dark:shadow-black/20" alt="image" />
            </div>
          </div>
         ))}
        </section>
        <div className='w-full max-w-7xl px-3'>
          <Link to={'#'}
          className='py-2 text-blue-950 font-bold text-xl hover:text-blue-900'>See more testimonials....
          </Link>
      </div>
    </div>
  );
}

export default Testimonials;








// {testimonials.map((testimonial, index) => (
//   <div key={index} className="flex  items-center w-[40rem]">
//     <div className="mb-12 md:mt-12 lg:mt-0 lg:mb-0">
//       <div
//         className="relative z-[1] block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[25px] dark:bg-[hsla(0,0%,5%,0.7)] dark:shadow-black/20 md:px-12 lg:-mr-14">
//         <h2 className="mb-2 text-3xl font-bold text-primary dark:text-primary-400">
//           {testimonial.name}
//         </h2>
//         <p className="mb-4 font-semibold">{testimonial.role}</p>
//         <p className="mb-6 text-neutral-500 dark:text-neutral-300">
//           {testimonial.content}
//         </p>
//         <ul className="flex justify-center lg:justify-start">
//           {[...Array(testimonial.rating)].map((_, index) => (
//             <li key={index}>
//               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960"
//                 className="w-5 text-primary dark:text-primary-400">
//                 <path fill="currentColor"
//                   d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
//               </svg>
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//     <div className="md:mb-12 lg:mb-0">
//       <img src={testimonial.image} className="lg:rotate-[6deg] w-full rounded-lg shadow-lg dark:shadow-black/20" alt="image" />
//     </div>
//   </div>
// ))}

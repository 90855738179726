import React from 'react'
import create from '../../assets/create.png'
import evolve from '../../assets/evolve.png'
import explore from '../../assets/explore.png'


const Card = ({ image, heading, details }) => {
    return (
      <div className="flex flex-col justify-center items-center max-w-sm rounded overflow-hidden py-14 hover:bg-white hover:shadow-lg">
        <img className="w-20 bounce-on-hover" src={image} alt="Card" />
        <div className="flex flex-col justify-center items-center px-6 py-4">
          <div className="font-bold lg:text-xl text-sm mb-2 text-center text-blue-950">{heading}</div>
          <p className="text-gray-700 lg:text-base text-sm text-center">{details}</p>
        </div>
      </div>
    );
  };

const WhatWeDo = () => {
  return (
    <div className='flex flex-col justify-center items-center w-full py-10 px-3'>

        <h1 className='text-orange-500 font-mono lg:mt-2 mt-10'>WHAT WE DO</h1>
        <h2 className='lg:py-10 py-5 text-blue-950 font-bold text-2xl'>
          Crafting Innovative Solutions to Propel Your Success
        </h2>

        <section className="flex sm:flex-row flex-col justify-center gap-5 mt-8  lg:px-0 px-4 max-w-7xl">
            <Card
                image= {explore}
                heading="Explore"
                details="
                  Embark on a transformative journey with iTechnia .Together, we'll explore your unique business needs,
                  diving deep into the challenges and opportunities that will ultimately shape our collaborative journey
                  towards innovation, growth, and success.
                "
            />
            <Card
                image={create}
                heading="Create"
                details="
                  Co-create innovative solutions tailored to your vision. Our process involves a hands-on approach,
                  working closely with you to design and develop websites that encapsulate your brand essence into a
                  trailblazer in the digital realm.
                "
            />
            <Card
                image={evolve}
                heading="Evolve"
                details="
                  Experience the transformative power of our dynamic solutions. We don't just deliver; we evolve with
                  your business, ensuring our technologies grow alongside your success, adapting to the ever-changing
                  landscape of the digital world.
                "
            />
        </section>

    </div>
  )
}

export default WhatWeDo

/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import imo_logo from '../../assets/logo_black.png'

const TrustedBy = () => {
  return (
    <div>
        <div className="container my-24 mx-auto md:px-6">
  <section className="mb-32 text-center">
  <h2 className='lg:pb-10 py-5 text-blue-950 font-bold text-2xl'>Trusted by over 200 users and companies</h2>

    <div className="grid px-6 md:grid-cols-4 lg:grid-cols-6 grid-cols-2 gap-4">
      <div className="mx-auto mb-12 lg:mb-0">
        <img
            src={imo_logo}
            alt='logo'
          className="max-w-[90px] dark:brightness-150" />
      </div>

      <div className="mx-auto mb-12 lg:mb-0">
        <img
            src={imo_logo}
            alt='logo'
          className="max-w-[90px] dark:brightness-150" />
      </div>

      <div className="mx-auto mb-12 lg:mb-0">
        <img
        src={imo_logo}
        alt='logo'
          className="max-w-[90px] dark:brightness-150" />
      </div>

      <div className="mx-auto mb-12 lg:mb-0">
        <img
        src={imo_logo}
        alt='logo'
          className="max-w-[90px] dark:brightness-150" />
      </div>

      <div className="mx-auto mb-12 lg:mb-0">
      <img
        src={imo_logo}
        alt='logo'
        className="max-w-[90px] dark:brightness-150" />
      </div>

      <div className="mx-auto mb-12 lg:mb-0">
        <img
        src={imo_logo}
        alt='logo'
          className="max-w-[90px] dark:brightness-150" />
      </div>
    </div>
  </section>
</div>
    </div>
  )
}

export default TrustedBy

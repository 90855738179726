import React from 'react';
import { Link } from 'react-router-dom';

import kenecare_doctor from '../../images/projects/kenecare_doctor.jpg'
import qrl_digicard from '../../images/projects/qrl_digicard.png'
import lion_mansa from '../../images/projects/lion_mansa.png'
import hr_management from '../../images/projects/hr_management.png'

interface Project {
  link: any,
  id: number;
  title: string;
  description: string;
  imageUrl: string;
}

const projectData: Project[] = [
  {
    link: 'https://www.kenecare.com',
    id: 1,
    title: 'Kenecare',
    description: 'Description for project 1 Description for project 2 Description for project 2 Description for project 2',
    imageUrl: kenecare_doctor,
  },
  {
    link: 'https://digicardsl.com',
    id: 2,
    title: 'DigiCard',
    description: 'Description for project 2 Description for project 2 Description for project 2',
    imageUrl: qrl_digicard,
  },
  {
    link: '#',
    id: 3,
    title: 'Mansa capital',
    description: 'Description for project 1 Description for project 2 Description for project 2 Description for project 2',
    imageUrl: lion_mansa,
  },
  {
    link: '#',
    id: 4,
    title: 'HR Management',
    description: 'Description for project 2 Description for project 2 Description for project 2',
    imageUrl: hr_management,
  },
  // Add more project objects as needed
];

const Projects: React.FC = () => {
  return (
    <div>
      <div className="flex justify-center items-center w-full">
        <section className="grid lg:grid-cols-3 sm:grid-cols-2 grid-flow-row mb-32 gap-3 text-center md:text-left py-10 px-3 max-w-7xl">
          {projectData.map((project) => (
            <a href={project.link} target='_blank' rel="noreferrer">
              <div
                key={project.id}
                className="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]
                lg:w-[25rem] sm:w-auto bounce-on-hover"
              >
                <div className="flex flex-wrap items-center">
                  <div className="block w-full shrink-0 grow-0 basis-auto md:w-4/12 lg:flex">
                    <img
                      src={project.imageUrl}
                      alt={project.title}
                      className="w-full sm:h-full h-[25rem] rounded-t-lg sm:rounded-tr-none sm:rounded-bl-lg"
                    />
                  </div>
                  <div className="w-full shrink-0 grow-0 basis-auto md:w-8/12">
                    <div className="px-6 py-5 sm:px-4">
                      <h2 className="mb-3 text-xl font-semibold font-Poppins">
                        <span className="text-blue-600">{project.title}</span>
                      </h2>
                      <p className="pb-2 text-gray-600 text-xs font-Poppins">{project.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          ))}
        </section>
      </div>
    </div>
  );
};

export default Projects;

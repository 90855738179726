import React, { useState } from 'react';
import Lottie from 'lottie-react'

import graphic_design from '../../lottie/graphic_design.json'
import web_design from '../../lottie/web_design.json'
import ux_ui from '../../lottie/ux_ui.json'
import sco from '../../lottie/sco.json'
import digital_marketing from '../../lottie/digital_marketing.json'


import exploration from '../../assets/continuous_exploration.png'
import experiments from '../../assets/iterative_experiments.png'
import implement from '../../assets/collaborative_implement.png'




interface Service {
    imageFile: any,
    title: string;
    subtitle: string;
    description: string;
    features: { title: string; description: string; imageSrc: string }[];
  }

  const services: Service[] = [
    {
      imageFile: graphic_design,
      title: 'STRATEGIC CREATIVITY',
      subtitle: 'Graphic Designing Excellence Redefined',
      description: `
            Step into IMO-Tech’s Graphic Designing realm, where innovative thinking meets strategic creativity.
            Our team crafts compelling designs, from logos to marketing collateral, enhancing your brand identity.
            Collaborate with us through a process involving consultation, iterative refinement, and timely delivery.
            We prioritize a strategic approach, merging creativity with intent to tell a powerful story through designs.
            Elevate your brand visuals and market presence with our efficient and collaborative Graphic Designing services.
            Contact IMO-Tech to transform your ideas into visually compelling realities.`,
      features: [
        {
          title: 'Strategic Creativity',
          description: 'Our designs blend creativity with strategic intent, ensuring visual elements align with your brand\'s narrative and goals.',
          imageSrc: exploration,
        },
        {
          title: 'Comprehensive Solutions',
          description: 'From logos to marketing collateral, our graphic designing services cover a spectrum of visual communication needs.',
          imageSrc: experiments,
        },
        {
          title: 'Brand Identity Enhancement',
          description: 'Elevate your brand visuals and create a distinct identity in the market with our customized graphic solutions.',
          imageSrc: implement,
        },
      ],
    },

    {
        imageFile: web_design,
        title: 'WEB DESIGN EXCELLENCE',
        subtitle: 'Crafting Digital Experiences Beyond Imagination with Web Technology',
        description: `
            Embark on a journey of digital transformation with IMO-Tech’s unparalleled web design services.
            Our expert team is dedicated to creating immersive and user-centric websites that captivate audiences and
            drive results. Collaborate with us to redefine your online presence through innovative design, seamless
            functionality, and intuitive navigation. We specialize in crafting responsive and visually stunning websites
            tailored to your brand's unique identity and objectives. From concept to deployment, we prioritize excellence,
            ensuring your website stands out in today's competitive digital landscape. Elevate your online success with
            IMO-Tech cutting-edge web design solutions. Contact us now to embark on your digital journey.`,
        features: [
          {
            title: 'Innovative Design',
            description: `Our web designs push boundaries, incorporating the  latest trends and technologies to deliver
                            visually striking and memorable digital experiences.`,
            imageSrc: exploration,
          },
          {
            title: 'User-Centric Approach',
            description: `We prioritize user experience, ensuring intuitive navigation and seamless functionality across
                devices to engage and delight your audience.`,
            imageSrc: experiments,
          },
          {
            title: 'Responsive Solutions',
            description: `With a focus on responsiveness, our websites adapt seamlessly to various screen sizes,
                providing an optimal viewing experience for users on desktops, tablets, and mobile devices.`,
            imageSrc: implement,
          },
        ],
      },

      {
        imageFile: ux_ui,
        title: 'ENGAGING SOLUTIONS',
        subtitle: 'Elevate Experiences with UI-UX Design',
        description: `
            Welcome to IMO-Tech's UI-UX Design hub, where creativity meets functionality to ensure seamless and
            delightful user experiences. Our dedicated team specializes in crafting interfaces that go beyond aesthetics,
            prioritizing intuitive design for a user-centric approach.`,
        features: [
          {
            title: 'Intuitive Design',
            description: `Our focus is on creating interfaces that users can navigate effortlessly, ensuring a positive
                and engaging experience.`,
            imageSrc: exploration,
          },
          {
            title: 'Responsive Solutions',
            description: `Our UI-UX designs are responsive across devices, providing a consistent and enjoyable experience.`,
            imageSrc: experiments,
          },
          {
            title: 'User-Centric Approach',
            description: `We prioritize the needs and preferences of the end-user, ensuring every interaction is natural
                and purposeful.`,
            imageSrc: implement,
          },
        ],
      },

      {
        imageFile: sco,
        title: 'STRATEGIC OPTIMIZATION',
        subtitle: 'SCO. Boost Visibility with Expert Search Engine Optimization Services',
        description: `
            Welcome to IMO-Tech’s Search Engine Optimization (SEO) expertise, where we redefine your digital presence
            by strategically optimizing your website. Our dedicated team specializes in search engine optimization
            techniques that elevate your rankings, increase organic traffic, and ensure your brand stands out in the
            competitive online landscape.`,
        features: [
          {
            title: 'On-Page SEO',
            description: `Optimize your website's structure, content, and meta tags for search engine visibility and user experience.`,
            imageSrc: exploration,
          },
          {
            title: 'Off-Page SEO',
            description: `Build high-quality backlinks, social signals, and online presence to enhance your website's
                authority and credibility.`,
            imageSrc: experiments,
          },
          {
            title: 'Keyword Research',
            description: `Conduct in-depth keyword research to target relevant search terms and improve your website's
                visibility for your target audience.`,
            imageSrc: implement,
          },
        ],
      },

      {
        imageFile: digital_marketing,
        title: 'COMPREHENSIVE DIGITAL MARKETING SOLUTIONS"',
        subtitle: 'Elevate Your Online Presence with Expert Digital Marketing Strategies',
        description: `
            Welcome to IMO-Tech’s comprehensive digital marketing solutions, where we empower your brand to thrive in
            the dynamic online landscape. Our team of digital marketing experts crafts tailored strategies to enhance
            your brand visibility, engage your target audience, and drive measurable results. From search engine
            optimization (SEO) and social media marketing to content creation and paid advertising, we offer a holistic
            approach to maximize your online success. Collaborate with us to leverage cutting-edge digital marketing
            techniques and stay ahead of the competition.`,
        features: [
          {
            title: 'Search Engine Optimization (SEO)',
            description: `Optimize your website to rank higher in search engine results pages (SERPs) and attract
            organic traffic through strategic keyword targeting and technical optimization.`,
            imageSrc: exploration,
          },
          {
            title: 'Social Media Marketing',
            description: `Engage with your audience across various social media platforms, build brand awareness,
            and drive conversions through targeted content creation, community management, and advertising campaigns.`,
            imageSrc: experiments,
          },
          {
            title: 'Content Marketing',
            description: `Create valuable and relevant content to attract, educate, and engage your audience,
                establishing your brand as an authority in your industry and driving long-term customer loyalty.`,
            imageSrc: implement,
          },
        ],
      },



    // Add more services as needed
  ];

  const AllServices: React.FC = () => {
    const [searchQuery, setSearchQuery] = useState<string>('');

    const filteredServices = services.filter(service =>
      service.subtitle.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
      <div className="flex flex-col justify-center items-center w-full mt-10">

        <div className='flex justify-center items-center w-full sm:px-0 px-4'>
            <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            className="border border-gray-300 rounded px-3 py-1 mb-4 sm:w-1/3 w-full"
            />
        </div>



        {filteredServices.map((service, index) => (
          <div key={index} className="flex sm:flex-row flex-col w-full max-w-7xl py-7">
            <div className="sm:w-[30rem] sm:px-3 px-8">
                <Lottie animationData={service.imageFile} width={200} height={200} loop={true} />
            </div>

            <div className="w-full px-4 sm:border-x">
              <h1 className="text-orange-500 font-mono lg:mt-2 mt-10">{service.title}</h1>
              <h2 className="lg:pt-5 py-1 text-blue-950 font-bold text-2xl mb-6">{service.subtitle}</h2>

              <p className="text-sm text-gray-700 font-Archivo max-w-[30rem]">{service.description}</p>
            </div>

            <div className="px-3">
              <h2 className="lg:pt-5 py-1 text-blue-950 font-bold text-lg mb-2 sm:mt-0 mt-2">Key Features:</h2>
              {service.features.map((feature, index) => (
                <div key={index}>
                  <img src={feature.imageSrc} alt="graphic" className="w-[4rem] object-cover" />
                  <h2 className="lg:pt-2 pt-1 text-blue-950 font-bold text-sm mb-2">{feature.title}</h2>
                  <p className="text-xs text-gray-700 font-Archivo sm:max-w-[35rem]">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  export default AllServices;

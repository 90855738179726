import React from 'react'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import kenecare from '../../images/projects/kenecare.png'
import digicard from '../../images/projects/digicard.png'
import mansa from '../../images/projects/mansa.png'
import { Link } from 'react-router-dom';

const projects = [
  {
    image: kenecare,
  },
  {
    image: digicard,
  },
  {
    image: mansa,
  },
]

const ProjectShowcase = () => {

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <div className='flex justify-center w-full bg-white'>
        <div className='w-full flex sm:flex-row flex-col max-w-7xl sm:pt-[10rem] py-10 px-3'>
            <div className='sm:w-1/2 sm:px-0 px-3'>
              <h1 className='text-orange-500 font-mono'>SHOWCASING EXCELLENCE</h1>
              <h1 className='text-blue-950 font-poppins font-bold text-3xl py-5'>
                  IMO-Tech's Project Showcase
              </h1>

              <p className="text-gray-700 lg:text-base text-sm text-start sm:py-0 py-6">
                Embark on a visual journey through our featured projects, witnessing the seamless fusion of
                creativity and expertise that defines IMO-Tech’s approach to turning concepts into successful
                technological ventures.
              </p>

              <Link to='/projects'
                className="relative inline-flex h-12 overflow-hidden rounded-sm p-[1px] focus:outline-none
                focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 my-10 ">
                <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-sm px-5 hover:bg-gray-800 bg-gray-50 py-1 text-sm font-medium hover:text-white text-gray-700 backdrop-blur-3xl">
                  VIEW ALL PROJECTS
                </span>
              </Link>

            </div>

            <div className='sm:w-1/2'>

            <div className="max-w-2x mx-auto w-full h-full">
              <Carousel
              responsive={responsive}
              infinite
              draggable
              swipeable
              centerMode={false}
              autoPlay={true}
              keyBoardControl={true}
              removeArrowOnDeviceType={['tablet', 'mobile']}
            >
              {projects.map((member, index) => (
                <div
                  key={index}
                  className="mx-4"
                  data-aos="fade-up"
                  data-aos-delay={(index + 1) * 100}
                >
                  <div className="rounded shadow p-6 flex flex-col justify-center items-center">
                    <img
                      src={member.image}
                      alt='team'
                      className="w-full h-full "
                    />
                  </div>
                </div>
                ))}
              </Carousel>
            </div>

            </div>

        </div>

    </div>
  )
}

export default ProjectShowcase

"use client";
import React from 'react'
import NavBar from '../NavBar'
import {BackgroundBeams} from '../ui/background-beams'

const Banner = () => {

  return (
    <div className='flex flex-col justify-center items-center w-full'>

      <section className="lg:h-[20rem]  w-full  bg-gradient-to-t from-blue-950 via-blue-800 to-blue-950 relative flex
        flex-col items-center antialiased lg:pb-0 pb-16">
      <NavBar />

      <div className='w-full h-full flex flex-col justify-center items-center max-w-7xl'>
        <h1 className='text-3xl font-Poppins font-extrabold text-white text-center'>
          Contact Us
        </h1>
        <p className='mt-2 text-white font-Poppins text-sm text-center'>
          Home <span className='text-orange-500 text-5xl'>.</span> Contact Us
        </p>
      </div>
      <BackgroundBeams />
    </section>

    </div>

  )
}

export default Banner

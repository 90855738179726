import React from 'react'
import Banner from '../components/home/Banner'
import InnovationLifecycle from '../components/home/InnovationLifecycle'
import OurServices from '../components/home/OurServices'
import ProjectShowcase from '../components/home/ProjectShowcase'
import { GetInTouch } from '../components/GetInTouch'

export const Home = () => {
  return (
    <div className='flex flex-col justify-center items-center w-full bg-white'>
      <div className='w-full max-w-[125rem]'>
        <Banner />
        <InnovationLifecycle/>
        <OurServices />
        <ProjectShowcase/>
        <GetInTouch />
      </div>

    </div>
  )
}


import React from 'react'
import Banner from '../components/contact_us/Banner'
import Footer from '../components/Footer'
import { GetInTouch } from '../components/GetInTouch'

const ContactUs = () => {
  return (
    <div className='flex flex-col justify-center items-center w-full bg-white'>
    <div className='w-full max-w-[125rem]'>
      <Banner/>
      <div className="flex flex-col items-center w-full bg-gradient-to-t from-blue-50 to-gray-50 pb-10">
        <GetInTouch/>
      </div>
    </div>
  </div>
  )
}

export default ContactUs

import React from 'react'
import Banner from '../components/services/Banner'
import AllServices from '../components/services/AllServices'
import Footer from '../components/Footer'

const Services = () => {
  return (
    <div className='flex flex-col justify-center items-center w-full bg-white'>
      <div className='w-full max-w-[125rem]'>
        <Banner/>
        <div className="flex flex-col items-center w-full bg-gradient-to-t from-blue-50 to-gray-50 pb-10">
          <AllServices/>
          <Footer/>
        </div>
        </div>

    </div>
  )
}

export default Services

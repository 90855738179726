import React from "react";
import { Label } from "../components/ui/label";
import { Input } from "../components/ui/input";
import { cn } from "../utils/cn";
import Footer from "./Footer";



export const GetInTouch = () => {
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log("Form submitted");
      };
      return (

        <div className="flex flex-col justify-center items-center w-full bg-gradient-to-t from-blue-100 to-gray-50 ">

          <div className="flex sm:flex-row-reverse flex-col px-3 w-full max-w-7xl sm:mt-10 mt-7 gap-5">
            <div className="sm:w-1/2 w-full">
              <h1 className='text-gradient font-poppins font-extrabold text-5xl py-5'>
                Get in Touch <br/> with IMO-Tech
              </h1>

              <div className="w-[5rem] h-3 my-10 bg-gradient-to-t from-blue-500 to-gray-300"></div>

              <p className="text-gray-700 lg:text-base text-lg text-start sm:py-0 py-6">
              Reach out to us for tailored technology solutions and personalized service. <br/> We’re here to transform your
              ideas into innovative realities.
              </p>
            </div>

            <div className="sm:w-1/2 w-full mx-auto rounded-none md:rounded-2xl p-4 sm:p-8 shadow-input ">
            <form className="my-8" onSubmit={handleSubmit}>
              <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mb-4">
                <LabelInputContainer>
                  <Label htmlFor="firstname">Full Name</Label>
                  <Input id="firstname" placeholder="Enter full name" type="text" />
                </LabelInputContainer>
                <LabelInputContainer>
                  <Label htmlFor="email">Email Address</Label>
                  <Input id="email" placeholder="Email" type="email" />
                </LabelInputContainer>
              </div>

              <LabelInputContainer className="mb-4">
                <Label htmlFor="phone">Phone Number</Label>
                <Input id="phone" placeholder="Enter phone number" type="phone" />
              </LabelInputContainer>
              <LabelInputContainer className="mb-4">
                <Label htmlFor="textarea">Message</Label>
                <Input id="textarea" placeholder="Enter text" type="textarea" />
              </LabelInputContainer>

              <button
                className="bg-gradient-to-br relative group/btn from-black  to-blue-700 block
                  w-full text-white rounded-md h-10 font-medium
                  shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]
                "
                type="submit"
              >
                Submit &rarr;
                <BottomGradient />
              </button>

              <div className="bg-gradient-to-r from-transparent via-neutral-300 dark:via-neutral-700 to-transparent my-8 h-[1px] w-full" />

            </form>
            </div>
          </div>
          <Footer />
        </div>
      );
    }

    const BottomGradient = () => {
      return (
        <>
          <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
          <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
        </>
      );
    };

    const LabelInputContainer = ({
      children,
      className,
    }: {
      children: React.ReactNode;
      className?: string;
    }) => {
      return (
        <div className={cn("flex flex-col space-y-2 w-full", className)}>
          {children}
        </div>
      );
    };



/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import Banner from '../components/projects/Banner'
import ProjectsComponent from '../components/projects/Projects'
import Footer from '../components/Footer'


const Projects = () => {
  return (
    <div className='flex flex-col justify-center items-center w-full bg-white'>
      <div className='w-full max-w-[125rem]'>
        <Banner/>
        <div className="flex flex-col items-center w-full bg-gradient-to-t from-blue-50 to-gray-50 pb-10">
            <ProjectsComponent/>
            <Footer/>
        </div>
      </div>
    </div>
  )
}

export default Projects;
